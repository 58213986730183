/*! Application print styles */

@import './config/variables';

@media print {
  // Global style
  body {
    font-size: 12pt;
  }

  .site-wrapper {
    background-color: initial;
  }

  .main {
    padding-top: 0;
  }

  .h1-style {
    font-size: 35pt;
  }

  .h2-style {
    font-size: 28pt;
  }

  .h3-style {
    font-size: 20pt;
  }

  .h4-style {
    font-size: 18pt;
  }

  .h5-style {
    font-size: 14pt;
  }

  // Header style
  .Header,
  .Header-top {
    position: static;
  }

  .Header-container {
    padding-left: 0;
    padding-right: 0;
  }

  .Header-content {
    margin-left: 0;
    margin-right: 0;
  }

  .Header-logo {
    &:not(.Header-logoTIS) {
      display: none;
    }
  }

  .Header-logoTIS {
    position: initial;
    transform: initial;
    padding: 0;
    color: map-get($color-brand, 'blue');
  }

  // Hero style
  .Hero {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    background: $color-gray;
    color: $color-black;
  }

  .Hero-title,
  .Hero-introduction,
  .Hero-dates {
    width: 100%;
  }

  .Hero-img,
  .Hero-breadcrumbs,
  .Hero-toolbar {
    display: none;
  }

  // Richtext style
  .AnchorMenu--is-sticked + .RichText {
    margin-top: 0;
  }

  .RichText-content {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .RichText {
    h2 {
      font-size: 25pt;
    }

    h3 {
      font-size: 20pt;
    }

    h4 {
      font-size: 16pt;
    }

    h5 {
      font-size: 14pt;
    }

    h2,
    h3,
    h4,
    h5 {
      font-weight: initial;
      break-inside: avoid;
    }

    ul li,
    ol li {
      break-inside: avoid;
    }

    p {
      font-weight: 400;
      orphans: 2;
      widows: 2;
    }

    a[href^="http"]:not([href*="example.com"]) {
      font-weight: initial;

      &::after {
        content: " (" attr(href) ")";
      }
    }

    .introduction {
      font-size: 12pt;
    }

    .accordion-title {
      font-size: 14pt;
      font-weight: 500;

      &::before,
      &::after {
        display: none;
      }
    }

    .accordion-content {
      display: block;
      padding-left: 0;
    }

    figure img,
    blockquote,
    .keyNumber,
    .accordion {
      break-inside: avoid;
    }
  }

  // Hidden components
  .AnchorMenu,
  .CtaBlock,
  .FaqAccordion,
  .InternalMesh,
  .PreFooter,
  .Footer {
    display: none;
  }
}
